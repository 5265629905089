import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  File: { input: any; output: any; }
  NonNegativeInt: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
};

export type Admin = {
  __typename?: 'Admin';
  adminRoleName?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  has2fa: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AdminLog = {
  __typename?: 'AdminLog';
  adminId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type AdminLogPagination = {
  __typename?: 'AdminLogPagination';
  adminLogs: Array<AdminLog>;
  cursor?: Maybe<Scalars['Int']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AdminPagination = {
  __typename?: 'AdminPagination';
  admins: Array<Admin>;
  cursor?: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AdminWithRooms = {
  __typename?: 'AdminWithRooms';
  adminRoleName?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roomIds: Array<Scalars['String']['output']>;
};

export type CreateAdminInput = {
  adminRoleName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGameInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  gameCategory: Scalars['String']['input'];
  gameSource: GameSource;
  imgUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePackageInput = {
  candy?: InputMaybe<Scalars['Int']['input']>;
  entries: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};

export type CreatePlayerInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  playFromHome?: InputMaybe<Scalars['Boolean']['input']>;
  roomId: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRoomInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  netRevenueBps?: InputMaybe<Scalars['NonNegativeInt']['input']>;
};

export type DataPoint = {
  __typename?: 'DataPoint';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type DataPoints = {
  __typename?: 'DataPoints';
  dataPoints: Array<DataPoint>;
  total: Scalars['Float']['output'];
};

export type EntryDataPoint = {
  __typename?: 'EntryDataPoint';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EntryDataPoints = {
  __typename?: 'EntryDataPoints';
  dataPoints: Array<EntryDataPoint>;
  numberOfEntries: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
};

export enum Frequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type Game = {
  __typename?: 'Game';
  displayName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  gameCategory: Scalars['String']['output'];
  gameTags: Array<GameTag>;
  id: Scalars['Int']['output'];
  imgUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export enum GameSource {
  Equinox = 'EQUINOX',
  Gmw = 'GMW',
  Internal = 'INTERNAL'
}

export type GameTag = {
  __typename?: 'GameTag';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type IntegerCursorPagination = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addGameTagToGame: Game;
  attachRoom: AdminWithRooms;
  buyPetPackage: Player;
  createAdmin: Admin;
  createDiscordChannel: Scalars['String']['output'];
  createGame: Game;
  createGameTag: GameTag;
  createPackage: Package;
  createPlayer: Player;
  createRoom: Room;
  createStation: StationWithSecret;
  deleteGameTag: Scalars['Boolean']['output'];
  deletePackage: Scalars['Boolean']['output'];
  detachRoom: AdminWithRooms;
  prizeRedemption: Player;
  removeGameTagFromGame: Game;
  requestTwoFactorAuth: Scalars['String']['output'];
  resetStationSecret: StationWithSecret;
  setPlayerEmailAndSalt: Scalars['Boolean']['output'];
  setupTwoFactorAuth: Admin;
  toggleAdmin: Admin;
  toggleStation: Station;
  toggleTauriAppActiveVersion: Scalars['Boolean']['output'];
  topUpBalance: Player;
  updateAdmin: Admin;
  updateGame: Game;
  updateGameTag: GameTag;
  updateOperatorReleaseNote: Scalars['Boolean']['output'];
  updatePackage: Package;
  updatePassword: Admin;
  updatePlayer: Player;
  updatePlayerCardSerialNumber: Scalars['Boolean']['output'];
  updatePlayerEmail: Scalars['Boolean']['output'];
  updatePlayerEntry: Scalars['Boolean']['output'];
  updatePlayerFirstName: Scalars['Boolean']['output'];
  updatePlayerFullName: Scalars['Boolean']['output'];
  updatePlayerLastName: Scalars['Boolean']['output'];
  updatePlayerPassword: Scalars['Boolean']['output'];
  updatePlayerPhoneNumber: Scalars['Boolean']['output'];
  updatePlayerPrizeRedemption: Scalars['Boolean']['output'];
  updatePlayerWinnings: Scalars['Boolean']['output'];
  updateRoom: Room;
  updateRoomBps: Scalars['Boolean']['output'];
  updateStation: Station;
  updateStationReleaseNote: Scalars['Boolean']['output'];
};


export type MutationAddGameTagToGameArgs = {
  gameId: Scalars['Int']['input'];
  gameTagId: Scalars['Int']['input'];
};


export type MutationAttachRoomArgs = {
  id: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationBuyPetPackageArgs = {
  petPackageId: Scalars['Int']['input'];
  playerId: Scalars['String']['input'];
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateGameArgs = {
  input: CreateGameInput;
};


export type MutationCreateGameTagArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreatePackageArgs = {
  input: CreatePackageInput;
};


export type MutationCreatePlayerArgs = {
  input: CreatePlayerInput;
};


export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
};


export type MutationCreateStationArgs = {
  roomId: Scalars['String']['input'];
};


export type MutationDeleteGameTagArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePackageArgs = {
  id: Scalars['Int']['input'];
  twoFAToken: Scalars['String']['input'];
};


export type MutationDetachRoomArgs = {
  id: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationPrizeRedemptionArgs = {
  amount: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};


export type MutationRemoveGameTagFromGameArgs = {
  gameId: Scalars['Int']['input'];
  gameTagId: Scalars['Int']['input'];
};


export type MutationResetStationSecretArgs = {
  stationId: Scalars['String']['input'];
};


export type MutationSetPlayerEmailAndSaltArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationSetupTwoFactorAuthArgs = {
  token: Scalars['String']['input'];
};


export type MutationToggleAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationToggleStationArgs = {
  id: Scalars['String']['input'];
};


export type MutationToggleTauriAppActiveVersionArgs = {
  appType: Scalars['String']['input'];
  versionId: Scalars['Int']['input'];
};


export type MutationTopUpBalanceArgs = {
  bonus?: InputMaybe<Scalars['Int']['input']>;
  packages: Array<Scalars['Int']['input']>;
  playerId: Scalars['String']['input'];
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


export type MutationUpdateGameTagArgs = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateOperatorReleaseNoteArgs = {
  releaseNote: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationUpdatePackageArgs = {
  input: UpdatePackageInput;
};


export type MutationUpdatePasswordArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdatePlayerArgs = {
  input: UpdatePlayerInput;
};


export type MutationUpdatePlayerCardSerialNumberArgs = {
  cardSerialNumber?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['String']['input'];
};


export type MutationUpdatePlayerEmailArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerEntryArgs = {
  entry: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerFirstNameArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerFullNameArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdatePlayerLastNameArgs = {
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdatePlayerPasswordArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerPhoneNumberArgs = {
  id: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationUpdatePlayerPrizeRedemptionArgs = {
  id: Scalars['String']['input'];
  prizeRedemption: Scalars['Int']['input'];
};


export type MutationUpdatePlayerWinningsArgs = {
  id: Scalars['String']['input'];
  winnings: Scalars['Int']['input'];
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
};


export type MutationUpdateRoomBpsArgs = {
  bps: Scalars['NonNegativeInt']['input'];
  roomId: Scalars['String']['input'];
  twoFAToken: Scalars['String']['input'];
};


export type MutationUpdateStationArgs = {
  input: UpdateStationInput;
};


export type MutationUpdateStationReleaseNoteArgs = {
  releaseNote: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type Package = {
  __typename?: 'Package';
  candy: Scalars['Int']['output'];
  entries: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type PackageWithAmount = {
  __typename?: 'PackageWithAmount';
  amount: Scalars['Int']['output'];
  candy: Scalars['Int']['output'];
  entries: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type Pet = {
  __typename?: 'Pet';
  candyEarned: Scalars['Int']['output'];
  candyToNextLevel: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PetPackage = {
  __typename?: 'PetPackage';
  id: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type PlayTransaction = {
  __typename?: 'PlayTransaction';
  amount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  gameSessionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PlayTransactionPagination = {
  __typename?: 'PlayTransactionPagination';
  cursor?: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
  transactions: Array<PlayTransaction>;
};

export enum PlayTransactionType {
  Bet = 'BET',
  RollbackBet = 'ROLLBACK_BET',
  Win = 'WIN'
}

export type Player = {
  __typename?: 'Player';
  bonus: Scalars['Int']['output'];
  candy: Scalars['Int']['output'];
  cardSerialNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entry: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isPlayFromHome: Scalars['Boolean']['output'];
  isSessionActive?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  pets: Array<Pet>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  prizeRedemption: Scalars['Int']['output'];
  room?: Maybe<Room>;
  roomId: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
  winnings: Scalars['Int']['output'];
};

export type PlayerSession = {
  __typename?: 'PlayerSession';
  endDate?: Maybe<Scalars['String']['output']>;
  endEntry?: Maybe<Scalars['Int']['output']>;
  endWinnings?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  prizeRedemption?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['String']['output'];
  startEntry: Scalars['Int']['output'];
  startWinnings: Scalars['Int']['output'];
  stationId: Scalars['String']['output'];
};

export type PlayersQuery = {
  __typename?: 'PlayersQuery';
  cursor?: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  players: Array<Player>;
};

export type Query = {
  __typename?: 'Query';
  countStationsByRoomId: Scalars['Int']['output'];
  countTransactionsByRoomId: Scalars['Int']['output'];
  getActivePackages: Array<Package>;
  getAdmin: Admin;
  getAdminById: Admin;
  getAdminList: AdminPagination;
  getAdminListByRoomId: AdminPagination;
  getAdminLogListByAdminId: AdminLogPagination;
  getArchivedPackages: Array<Package>;
  getAverageEntryByGameId: DataPoints;
  getAverageEntryByPlayerId: DataPoints;
  getAverageSessionDurationByPlayerId: Scalars['Float']['output'];
  getEntriesByGameId: EntryDataPoints;
  getEntriesByRoomId: EntryDataPoints;
  getEntriesByStationId: EntryDataPoints;
  getGameList: Array<Game>;
  getGameTags: Array<GameTag>;
  getNetRevenueByAdminId: DataPoints;
  getNetRevenueByPlayerId: DataPoints;
  getNetRevenueByRoomId: DataPoints;
  getOperatorActiveVersionInstaller: Scalars['String']['output'];
  getOperatorAppActiveVersion: Scalars['String']['output'];
  getOperatorById: Admin;
  getOperatorList: AdminPagination;
  getPersonaList: Array<Scalars['String']['output']>;
  getPersonaListBelow: Array<Scalars['String']['output']>;
  getPetPackageList: Array<PetPackage>;
  getPlayTransactionListByPlayerId: PlayTransactionPagination;
  getPlayTransactionListByRoomId: PlayTransactionPagination;
  getPlayerById: Player;
  getPlayerByIdOrCardSerialNumber: Player;
  getPlayerList: PlayersQuery;
  getPlayerListByRoomId: PlayersQuery;
  getRoomById: Room;
  getRoomList: Array<Room>;
  getRoomListByAdminId: Array<Room>;
  getRoomNetRevenueBps: Scalars['Int']['output'];
  getRoomPayToProvider?: Maybe<Scalars['Float']['output']>;
  getSalesRepList: AdminPagination;
  getStationActiveVersionInstaller: Scalars['String']['output'];
  getStationAppActiveVersion: Scalars['String']['output'];
  getStationById: Station;
  getStationList: Array<Maybe<Station>>;
  getStationListByRoomId: Array<Maybe<Station>>;
  getStationReleaseNote: Scalars['String']['output'];
  getStationsForOperator: Array<Station>;
  getSuperAdminList: AdminPagination;
  getTauriAppVersions?: Maybe<Array<Maybe<TauriAppVersion>>>;
  getTransactionList: TransactionPagination;
  getTransactionListByPlayerId: TransactionPagination;
  getTransactionListByRoomId: TransactionPagination;
  issueCardValidation: Scalars['Boolean']['output'];
  searchPlayers: PlayersQuery;
};


export type QueryCountStationsByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryCountTransactionsByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetAdminByIdArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetAdminListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminListByRoomIdArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  roomId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminLogListByAdminIdArgs = {
  adminId: Scalars['String']['input'];
  pagination?: InputMaybe<IntegerCursorPagination>;
};


export type QueryGetAverageEntryByGameIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetAverageEntryByPlayerIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  playerId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetAverageSessionDurationByPlayerIdArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryGetEntriesByGameIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetEntriesByRoomIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  roomId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetEntriesByStationIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  stationId: Scalars['String']['input'];
};


export type QueryGetGameListArgs = {
  gameTag?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetNetRevenueByAdminIdArgs = {
  adminId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameSource?: InputMaybe<GameSource>;
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetNetRevenueByPlayerIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameSource?: InputMaybe<GameSource>;
  playerId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetNetRevenueByRoomIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameSource?: InputMaybe<GameSource>;
  roomId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetOperatorByIdArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetOperatorListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlayTransactionListByPlayerIdArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  playerId: Scalars['String']['input'];
};


export type QueryGetPlayTransactionListByRoomIdArgs = {
  filter?: InputMaybe<GetPlayTransactionListFilter>;
  pagination?: InputMaybe<StringCursorPagination>;
  roomId: Scalars['String']['input'];
};


export type QueryGetPlayerByIdArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryGetPlayerByIdOrCardSerialNumberArgs = {
  playerIdOrCardSerialNumber: Scalars['String']['input'];
};


export type QueryGetPlayerListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
};


export type QueryGetPlayerListByRoomIdArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  roomId: Scalars['String']['input'];
};


export type QueryGetRoomByIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetRoomListByAdminIdArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetRoomNetRevenueBpsArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetRoomPayToProviderArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  gameSource?: InputMaybe<GameSource>;
  roomId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetSalesRepListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStationByIdArgs = {
  stationId: Scalars['String']['input'];
};


export type QueryGetStationListByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetStationReleaseNoteArgs = {
  version: Scalars['String']['input'];
};


export type QueryGetStationsForOperatorArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetSuperAdminListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTauriAppVersionsArgs = {
  appType: Scalars['String']['input'];
};


export type QueryGetTransactionListArgs = {
  filter?: InputMaybe<GetTransactionListFilter>;
  pagination?: InputMaybe<IntegerCursorPagination>;
};


export type QueryGetTransactionListByPlayerIdArgs = {
  filter?: InputMaybe<GetTransactionListFilter>;
  pagination?: InputMaybe<IntegerCursorPagination>;
  playerId: Scalars['String']['input'];
};


export type QueryGetTransactionListByRoomIdArgs = {
  filter?: InputMaybe<GetTransactionListFilter>;
  pagination?: InputMaybe<IntegerCursorPagination>;
  roomId: Scalars['String']['input'];
};


export type QueryIssueCardValidationArgs = {
  playerId: Scalars['String']['input'];
};


export type QuerySearchPlayersArgs = {
  input: SearchPlayersInput;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['String']['output'];
  isReadyForUpdate?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
};

export type SearchPlayersInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['PositiveInt']['input']>;
  query: Scalars['String']['input'];
  roomId?: InputMaybe<Scalars['String']['input']>;
};

export type Station = {
  __typename?: 'Station';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  playerSession?: Maybe<Array<Maybe<PlayerSession>>>;
  roomId: Scalars['String']['output'];
  stationAppVersion?: Maybe<Scalars['String']['output']>;
};

export type StationWithSecret = {
  __typename?: 'StationWithSecret';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  roomId: Scalars['String']['output'];
  secret?: Maybe<Scalars['String']['output']>;
};

export type StringCursorPagination = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type TauriAppVersion = {
  __typename?: 'TauriAppVersion';
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  pubDate: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  adminId: Scalars['String']['output'];
  adminName?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  bonus: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  packages?: Maybe<Array<Maybe<PackageWithAmount>>>;
  petPackage?: Maybe<PetPackage>;
  playerId: Scalars['String']['output'];
  playerName?: Maybe<Scalars['String']['output']>;
  roomId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TransactionPagination = {
  __typename?: 'TransactionPagination';
  cursor?: Maybe<Scalars['Int']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalDeposits?: Maybe<Scalars['Int']['output']>;
  totalDepositsAndWithdraws?: Maybe<Scalars['Int']['output']>;
  totalWithdraws?: Maybe<Scalars['Int']['output']>;
  transactions: Array<Transaction>;
};

export enum TransactionType {
  Deposit = 'DEPOSIT',
  PetPackage = 'PET_PACKAGE',
  Withdraw = 'WITHDRAW'
}

export type UpdateAdminInput = {
  adminRoleName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGameInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  gameCategory?: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['Int']['input'];
  gameSource: GameSource;
  imgUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePackageInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdatePlayerInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  entry?: InputMaybe<Scalars['PositiveInt']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['String']['input'];
  prizeRedemption?: InputMaybe<Scalars['PositiveInt']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  winnings?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type UpdateRoomInput = {
  isReadyForUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  netRevenueBps?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  roomId: Scalars['String']['input'];
};

export type UpdateStationInput = {
  certificateFingerprint?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  stationId: Scalars['String']['input'];
};

export type GetPlayTransactionListFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<PlayTransactionType>;
};

export type GetTransactionListFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<TransactionType>;
};

export type PlayerFragment = { __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> };

export type AdminFragment = { __typename?: 'Admin', id: string, name?: string | null, email?: string | null, code?: string | null, isActive?: boolean | null, adminRoleName?: string | null };

export type TransactionFragment = { __typename?: 'Transaction', id: number, playerId: string, playerName?: string | null, adminId: string, adminName?: string | null, roomId: string, amount: number, bonus: number, type: string, date: string, packages?: Array<{ __typename?: 'PackageWithAmount', id: number, name: string, price: number, entries: number, candy: number, amount: number } | null> | null };

export type PlayTransactionFragment = { __typename?: 'PlayTransaction', id: string, gameSessionId: string, type: string, date: string, amount: number };

export type PackageWithAmountFragment = { __typename?: 'PackageWithAmount', id: number, name: string, price: number, entries: number, candy: number, amount: number };

export type PackageFragment = { __typename?: 'Package', id: number, name: string, price: number, entries: number, candy: number };

export type PlayerSessionFragment = { __typename?: 'PlayerSession', id: string, playerId: string, stationId: string, startDate: string, endDate?: string | null, startEntry: number, endEntry?: number | null, startWinnings: number, endWinnings?: number | null, prizeRedemption?: number | null };

export type StationFragment = { __typename?: 'Station', id: string, roomId: string, isActive?: boolean | null, stationAppVersion?: string | null, createdAt?: string | null, playerSession?: Array<{ __typename?: 'PlayerSession', id: string, playerId: string, stationId: string, startDate: string, endDate?: string | null, startEntry: number, endEntry?: number | null, startWinnings: number, endWinnings?: number | null, prizeRedemption?: number | null } | null> | null };

export type GetRoomListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoomListQuery = { __typename?: 'Query', getRoomList: Array<{ __typename?: 'Room', id: string, name: string, location?: string | null, isReadyForUpdate?: boolean | null }> };

export type GetTransactionListByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
  pagination?: InputMaybe<IntegerCursorPagination>;
  filter?: InputMaybe<GetTransactionListFilter>;
}>;


export type GetTransactionListByRoomIdQuery = { __typename?: 'Query', getTransactionListByRoomId: { __typename?: 'TransactionPagination', cursor?: number | null, hasMore: boolean, totalCount?: number | null, totalDepositsAndWithdraws?: number | null, totalDeposits?: number | null, totalWithdraws?: number | null, transactions: Array<{ __typename?: 'Transaction', id: number, playerId: string, playerName?: string | null, adminId: string, adminName?: string | null, roomId: string, amount: number, bonus: number, type: string, date: string, packages?: Array<{ __typename?: 'PackageWithAmount', id: number, name: string, price: number, entries: number, candy: number, amount: number } | null> | null }> } };

export type GetTransactionListByPlayerIdQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
  pagination?: InputMaybe<IntegerCursorPagination>;
  filter?: InputMaybe<GetTransactionListFilter>;
}>;


export type GetTransactionListByPlayerIdQuery = { __typename?: 'Query', getTransactionListByPlayerId: { __typename?: 'TransactionPagination', cursor?: number | null, hasMore: boolean, totalCount?: number | null, totalDepositsAndWithdraws?: number | null, totalDeposits?: number | null, totalWithdraws?: number | null, transactions: Array<{ __typename?: 'Transaction', id: number, playerId: string, playerName?: string | null, adminId: string, adminName?: string | null, roomId: string, amount: number, bonus: number, type: string, date: string, packages?: Array<{ __typename?: 'PackageWithAmount', id: number, name: string, price: number, entries: number, candy: number, amount: number } | null> | null }> } };

export type GetPlayTransactionListByPlayerIdQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetPlayTransactionListByPlayerIdQuery = { __typename?: 'Query', getPlayTransactionListByPlayerId: { __typename?: 'PlayTransactionPagination', cursor?: string | null, hasMore: boolean, totalCount?: number | null, transactions: Array<{ __typename?: 'PlayTransaction', id: string, gameSessionId: string, type: string, date: string, amount: number }> } };

export type IssueCardValidationQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type IssueCardValidationQuery = { __typename?: 'Query', issueCardValidation: boolean };

export type GetPlayerListQueryVariables = Exact<{
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetPlayerListQuery = { __typename?: 'Query', getPlayerList: { __typename?: 'PlayersQuery', cursor?: string | null, hasMore: boolean, players: Array<{ __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> }> } };

export type GetPlayerListByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetPlayerListByRoomIdQuery = { __typename?: 'Query', getPlayerListByRoomId: { __typename?: 'PlayersQuery', cursor?: string | null, hasMore: boolean, players: Array<{ __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> }> } };

export type GetPlayerByIdOrCardSerialNumberQueryVariables = Exact<{
  playerIdOrCardSerialNumber: Scalars['String']['input'];
}>;


export type GetPlayerByIdOrCardSerialNumberQuery = { __typename?: 'Query', getPlayerByIdOrCardSerialNumber: { __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> } };

export type GetPlayerByIdQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type GetPlayerByIdQuery = { __typename?: 'Query', getPlayerById: { __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> } };

export type GetAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminQuery = { __typename?: 'Query', getAdmin: { __typename?: 'Admin', id: string, name?: string | null, email?: string | null, code?: string | null, isActive?: boolean | null, adminRoleName?: string | null } };

export type GetSuperAdminListQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetSuperAdminListQuery = { __typename?: 'Query', getSuperAdminList: { __typename?: 'AdminPagination', cursor?: string | null, hasMore: boolean, totalCount: number, admins: Array<{ __typename?: 'Admin', id: string, name?: string | null, email?: string | null, code?: string | null, isActive?: boolean | null, adminRoleName?: string | null }> } };

export type GetStationListByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
}>;


export type GetStationListByRoomIdQuery = { __typename?: 'Query', getStationListByRoomId: Array<{ __typename?: 'Station', id: string, roomId: string, isActive?: boolean | null, stationAppVersion?: string | null, createdAt?: string | null, playerSession?: Array<{ __typename?: 'PlayerSession', id: string, playerId: string, stationId: string, startDate: string, endDate?: string | null, startEntry: number, endEntry?: number | null, startWinnings: number, endWinnings?: number | null, prizeRedemption?: number | null } | null> | null } | null> };

export type GetStationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStationListQuery = { __typename?: 'Query', getStationList: Array<{ __typename?: 'Station', id: string, roomId: string, isActive?: boolean | null, stationAppVersion?: string | null, createdAt?: string | null, playerSession?: Array<{ __typename?: 'PlayerSession', id: string, playerId: string, stationId: string, startDate: string, endDate?: string | null, startEntry: number, endEntry?: number | null, startWinnings: number, endWinnings?: number | null, prizeRedemption?: number | null } | null> | null } | null> };

export type GetStationAppActiveVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStationAppActiveVersionQuery = { __typename?: 'Query', getStationAppActiveVersion: string };

export type GetOperatorAppActiveVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOperatorAppActiveVersionQuery = { __typename?: 'Query', getOperatorAppActiveVersion: string };

export type SearchPlayersQueryVariables = Exact<{
  input: SearchPlayersInput;
}>;


export type SearchPlayersQuery = { __typename?: 'Query', searchPlayers: { __typename?: 'PlayersQuery', cursor?: string | null, hasMore: boolean, players: Array<{ __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> }> } };

export type GetActivePackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActivePackagesQuery = { __typename?: 'Query', getActivePackages: Array<{ __typename?: 'Package', id: number, name: string, price: number, entries: number, candy: number }> };

export type GetStationReleaseNoteQueryVariables = Exact<{
  version: Scalars['String']['input'];
}>;


export type GetStationReleaseNoteQuery = { __typename?: 'Query', getStationReleaseNote: string };

export type CreatePlayerMutationVariables = Exact<{
  input: CreatePlayerInput;
}>;


export type CreatePlayerMutation = { __typename?: 'Mutation', createPlayer: { __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> } };

export type SetPlayerEmailAndSaltMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type SetPlayerEmailAndSaltMutation = { __typename?: 'Mutation', setPlayerEmailAndSalt: boolean };

export type TopUpBalanceMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  packages: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  bonus?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TopUpBalanceMutation = { __typename?: 'Mutation', topUpBalance: { __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> } };

export type PrizeRedemptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
}>;


export type PrizeRedemptionMutation = { __typename?: 'Mutation', prizeRedemption: { __typename?: 'Player', id: string, firstName: string, lastName: string, email?: string | null, entry: number, winnings: number } };

export type UpdatePlayerMutationVariables = Exact<{
  input: UpdatePlayerInput;
}>;


export type UpdatePlayerMutation = { __typename?: 'Mutation', updatePlayer: { __typename?: 'Player', id: string, roomId: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, candy: number, bonus: number, entry: number, winnings: number, prizeRedemption: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, images?: Array<string | null> | null, isSessionActive?: boolean | null, room?: { __typename?: 'Room', id: string, name: string } | null, pets: Array<{ __typename?: 'Pet', id: string, name: string, type: string, candyEarned: number, candyToNextLevel: number, level: number }> } };

export type UpdatePlayerCardSerialNumberMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  cardSerialNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdatePlayerCardSerialNumberMutation = { __typename?: 'Mutation', updatePlayerCardSerialNumber: boolean };

export type UpdatePlayerPasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type UpdatePlayerPasswordMutation = { __typename?: 'Mutation', updatePlayerPassword: boolean };

export type UpdatePlayerFullNameMutationVariables = Exact<{
  id: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
}>;


export type UpdatePlayerFullNameMutation = { __typename?: 'Mutation', updatePlayerFullName: boolean };

export type UpdatePlayerEntryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  entry: Scalars['Int']['input'];
}>;


export type UpdatePlayerEntryMutation = { __typename?: 'Mutation', updatePlayerEntry: boolean };

export type UpdatePlayerWinningsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  winnings: Scalars['Int']['input'];
}>;


export type UpdatePlayerWinningsMutation = { __typename?: 'Mutation', updatePlayerWinnings: boolean };

export type UpdatePlayerPrizeRedemptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  prizeRedemption: Scalars['Int']['input'];
}>;


export type UpdatePlayerPrizeRedemptionMutation = { __typename?: 'Mutation', updatePlayerPrizeRedemption: boolean };

export type UpdateRoomMutationVariables = Exact<{
  input: UpdateRoomInput;
}>;


export type UpdateRoomMutation = { __typename?: 'Mutation', updateRoom: { __typename?: 'Room', id: string, name: string, location?: string | null } };

export type CreateDiscordChannelMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateDiscordChannelMutation = { __typename?: 'Mutation', createDiscordChannel: string };

export type GetStationActiveVersionInstallerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStationActiveVersionInstallerQuery = { __typename?: 'Query', getStationActiveVersionInstaller: string };

export type CreateStationMutationVariables = Exact<{
  roomId: Scalars['String']['input'];
}>;


export type CreateStationMutation = { __typename?: 'Mutation', createStation: { __typename?: 'StationWithSecret', id: string, roomId: string, isActive?: boolean | null, secret?: string | null, createdAt?: string | null } };

export type ResetStationSecretMutationVariables = Exact<{
  stationId: Scalars['String']['input'];
}>;


export type ResetStationSecretMutation = { __typename?: 'Mutation', resetStationSecret: { __typename?: 'StationWithSecret', id: string, roomId: string, isActive?: boolean | null, secret?: string | null, createdAt?: string | null } };

export type ToggleStationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ToggleStationMutation = { __typename?: 'Mutation', toggleStation: { __typename?: 'Station', id: string, roomId: string, isActive?: boolean | null, createdAt?: string | null } };

export const PlayerFragmentDoc = gql`
    fragment Player on Player {
  id
  roomId
  username
  firstName
  lastName
  phoneNumber
  email
  candy
  bonus
  entry
  winnings
  prizeRedemption
  isPlayFromHome
  cardSerialNumber
  room {
    id
    name
  }
  images
  isSessionActive
  pets {
    id
    name
    type
    candyEarned
    candyToNextLevel
    level
  }
}
    `;
export const AdminFragmentDoc = gql`
    fragment Admin on Admin {
  id
  name
  email
  code
  isActive
  adminRoleName
}
    `;
export const PackageWithAmountFragmentDoc = gql`
    fragment PackageWithAmount on PackageWithAmount {
  id
  name
  price
  entries
  candy
  amount
}
    `;
export const TransactionFragmentDoc = gql`
    fragment Transaction on Transaction {
  id
  playerId
  playerName
  adminId
  adminName
  roomId
  amount
  bonus
  type
  date
  packages {
    ...PackageWithAmount
  }
}
    ${PackageWithAmountFragmentDoc}`;
export const PlayTransactionFragmentDoc = gql`
    fragment PlayTransaction on PlayTransaction {
  id
  gameSessionId
  type
  date
  amount
}
    `;
export const PackageFragmentDoc = gql`
    fragment Package on Package {
  id
  name
  price
  entries
  candy
}
    `;
export const PlayerSessionFragmentDoc = gql`
    fragment PlayerSession on PlayerSession {
  id
  playerId
  stationId
  startDate
  endDate
  startEntry
  endEntry
  startWinnings
  endWinnings
  prizeRedemption
}
    `;
export const StationFragmentDoc = gql`
    fragment Station on Station {
  id
  roomId
  isActive
  stationAppVersion
  createdAt
  playerSession {
    ...PlayerSession
  }
}
    ${PlayerSessionFragmentDoc}`;
export const GetRoomListDocument = gql`
    query getRoomList {
  getRoomList {
    id
    name
    location
    isReadyForUpdate
  }
}
    `;

export function useGetRoomListQuery(options?: Omit<Urql.UseQueryArgs<GetRoomListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRoomListQuery, GetRoomListQueryVariables>({ query: GetRoomListDocument, ...options });
};
export const GetTransactionListByRoomIdDocument = gql`
    query getTransactionListByRoomId($roomId: String!, $pagination: IntegerCursorPagination, $filter: getTransactionListFilter) {
  getTransactionListByRoomId(
    roomId: $roomId
    pagination: $pagination
    filter: $filter
  ) {
    transactions {
      ...Transaction
    }
    cursor
    hasMore
    totalCount
    totalDepositsAndWithdraws
    totalDeposits
    totalWithdraws
  }
}
    ${TransactionFragmentDoc}`;

export function useGetTransactionListByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetTransactionListByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionListByRoomIdQuery, GetTransactionListByRoomIdQueryVariables>({ query: GetTransactionListByRoomIdDocument, ...options });
};
export const GetTransactionListByPlayerIdDocument = gql`
    query getTransactionListByPlayerId($playerId: String!, $pagination: IntegerCursorPagination, $filter: getTransactionListFilter) {
  getTransactionListByPlayerId(
    playerId: $playerId
    pagination: $pagination
    filter: $filter
  ) {
    transactions {
      ...Transaction
    }
    cursor
    hasMore
    totalCount
    totalDepositsAndWithdraws
    totalDeposits
    totalWithdraws
  }
}
    ${TransactionFragmentDoc}`;

export function useGetTransactionListByPlayerIdQuery(options: Omit<Urql.UseQueryArgs<GetTransactionListByPlayerIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionListByPlayerIdQuery, GetTransactionListByPlayerIdQueryVariables>({ query: GetTransactionListByPlayerIdDocument, ...options });
};
export const GetPlayTransactionListByPlayerIdDocument = gql`
    query getPlayTransactionListByPlayerId($playerId: String!, $pagination: StringCursorPagination) {
  getPlayTransactionListByPlayerId(playerId: $playerId, pagination: $pagination) {
    transactions {
      ...PlayTransaction
    }
    cursor
    hasMore
    totalCount
  }
}
    ${PlayTransactionFragmentDoc}`;

export function useGetPlayTransactionListByPlayerIdQuery(options: Omit<Urql.UseQueryArgs<GetPlayTransactionListByPlayerIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayTransactionListByPlayerIdQuery, GetPlayTransactionListByPlayerIdQueryVariables>({ query: GetPlayTransactionListByPlayerIdDocument, ...options });
};
export const IssueCardValidationDocument = gql`
    query issueCardValidation($playerId: String!) {
  issueCardValidation(playerId: $playerId)
}
    `;

export function useIssueCardValidationQuery(options: Omit<Urql.UseQueryArgs<IssueCardValidationQueryVariables>, 'query'>) {
  return Urql.useQuery<IssueCardValidationQuery, IssueCardValidationQueryVariables>({ query: IssueCardValidationDocument, ...options });
};
export const GetPlayerListDocument = gql`
    query getPlayerList($pagination: StringCursorPagination) {
  getPlayerList(pagination: $pagination) {
    players {
      ...Player
    }
    cursor
    hasMore
  }
}
    ${PlayerFragmentDoc}`;

export function useGetPlayerListQuery(options?: Omit<Urql.UseQueryArgs<GetPlayerListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerListQuery, GetPlayerListQueryVariables>({ query: GetPlayerListDocument, ...options });
};
export const GetPlayerListByRoomIdDocument = gql`
    query getPlayerListByRoomId($roomId: String!, $pagination: StringCursorPagination) {
  getPlayerListByRoomId(roomId: $roomId, pagination: $pagination) {
    players {
      ...Player
    }
    cursor
    hasMore
  }
}
    ${PlayerFragmentDoc}`;

export function useGetPlayerListByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetPlayerListByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerListByRoomIdQuery, GetPlayerListByRoomIdQueryVariables>({ query: GetPlayerListByRoomIdDocument, ...options });
};
export const GetPlayerByIdOrCardSerialNumberDocument = gql`
    query getPlayerByIdOrCardSerialNumber($playerIdOrCardSerialNumber: String!) {
  getPlayerByIdOrCardSerialNumber(
    playerIdOrCardSerialNumber: $playerIdOrCardSerialNumber
  ) {
    ...Player
  }
}
    ${PlayerFragmentDoc}`;

export function useGetPlayerByIdOrCardSerialNumberQuery(options: Omit<Urql.UseQueryArgs<GetPlayerByIdOrCardSerialNumberQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerByIdOrCardSerialNumberQuery, GetPlayerByIdOrCardSerialNumberQueryVariables>({ query: GetPlayerByIdOrCardSerialNumberDocument, ...options });
};
export const GetPlayerByIdDocument = gql`
    query getPlayerById($playerId: String!) {
  getPlayerById(playerId: $playerId) {
    ...Player
  }
}
    ${PlayerFragmentDoc}`;

export function useGetPlayerByIdQuery(options: Omit<Urql.UseQueryArgs<GetPlayerByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerByIdQuery, GetPlayerByIdQueryVariables>({ query: GetPlayerByIdDocument, ...options });
};
export const GetAdminDocument = gql`
    query getAdmin {
  getAdmin {
    ...Admin
  }
}
    ${AdminFragmentDoc}`;

export function useGetAdminQuery(options?: Omit<Urql.UseQueryArgs<GetAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAdminQuery, GetAdminQueryVariables>({ query: GetAdminDocument, ...options });
};
export const GetSuperAdminListDocument = gql`
    query getSuperAdminList($search: String, $pagination: StringCursorPagination) {
  getSuperAdminList(search: $search, pagination: $pagination) {
    admins {
      ...Admin
    }
    cursor
    hasMore
    totalCount
  }
}
    ${AdminFragmentDoc}`;

export function useGetSuperAdminListQuery(options?: Omit<Urql.UseQueryArgs<GetSuperAdminListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSuperAdminListQuery, GetSuperAdminListQueryVariables>({ query: GetSuperAdminListDocument, ...options });
};
export const GetStationListByRoomIdDocument = gql`
    query getStationListByRoomId($roomId: String!) {
  getStationListByRoomId(roomId: $roomId) {
    ...Station
  }
}
    ${StationFragmentDoc}`;

export function useGetStationListByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetStationListByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationListByRoomIdQuery, GetStationListByRoomIdQueryVariables>({ query: GetStationListByRoomIdDocument, ...options });
};
export const GetStationListDocument = gql`
    query getStationList {
  getStationList {
    ...Station
  }
}
    ${StationFragmentDoc}`;

export function useGetStationListQuery(options?: Omit<Urql.UseQueryArgs<GetStationListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationListQuery, GetStationListQueryVariables>({ query: GetStationListDocument, ...options });
};
export const GetStationAppActiveVersionDocument = gql`
    query getStationAppActiveVersion {
  getStationAppActiveVersion
}
    `;

export function useGetStationAppActiveVersionQuery(options?: Omit<Urql.UseQueryArgs<GetStationAppActiveVersionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationAppActiveVersionQuery, GetStationAppActiveVersionQueryVariables>({ query: GetStationAppActiveVersionDocument, ...options });
};
export const GetOperatorAppActiveVersionDocument = gql`
    query getOperatorAppActiveVersion {
  getOperatorAppActiveVersion
}
    `;

export function useGetOperatorAppActiveVersionQuery(options?: Omit<Urql.UseQueryArgs<GetOperatorAppActiveVersionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOperatorAppActiveVersionQuery, GetOperatorAppActiveVersionQueryVariables>({ query: GetOperatorAppActiveVersionDocument, ...options });
};
export const SearchPlayersDocument = gql`
    query searchPlayers($input: SearchPlayersInput!) {
  searchPlayers(input: $input) {
    players {
      ...Player
    }
    cursor
    hasMore
  }
}
    ${PlayerFragmentDoc}`;

export function useSearchPlayersQuery(options: Omit<Urql.UseQueryArgs<SearchPlayersQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchPlayersQuery, SearchPlayersQueryVariables>({ query: SearchPlayersDocument, ...options });
};
export const GetActivePackagesDocument = gql`
    query getActivePackages {
  getActivePackages {
    ...Package
  }
}
    ${PackageFragmentDoc}`;

export function useGetActivePackagesQuery(options?: Omit<Urql.UseQueryArgs<GetActivePackagesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActivePackagesQuery, GetActivePackagesQueryVariables>({ query: GetActivePackagesDocument, ...options });
};
export const GetStationReleaseNoteDocument = gql`
    query getStationReleaseNote($version: String!) {
  getStationReleaseNote(version: $version)
}
    `;

export function useGetStationReleaseNoteQuery(options: Omit<Urql.UseQueryArgs<GetStationReleaseNoteQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationReleaseNoteQuery, GetStationReleaseNoteQueryVariables>({ query: GetStationReleaseNoteDocument, ...options });
};
export const CreatePlayerDocument = gql`
    mutation createPlayer($input: CreatePlayerInput!) {
  createPlayer(input: $input) {
    ...Player
  }
}
    ${PlayerFragmentDoc}`;

export function useCreatePlayerMutation() {
  return Urql.useMutation<CreatePlayerMutation, CreatePlayerMutationVariables>(CreatePlayerDocument);
};
export const SetPlayerEmailAndSaltDocument = gql`
    mutation setPlayerEmailAndSalt($id: String!, $email: String!) {
  setPlayerEmailAndSalt(id: $id, email: $email)
}
    `;

export function useSetPlayerEmailAndSaltMutation() {
  return Urql.useMutation<SetPlayerEmailAndSaltMutation, SetPlayerEmailAndSaltMutationVariables>(SetPlayerEmailAndSaltDocument);
};
export const TopUpBalanceDocument = gql`
    mutation topUpBalance($playerId: String!, $packages: [Int!]!, $bonus: Int) {
  topUpBalance(playerId: $playerId, packages: $packages, bonus: $bonus) {
    ...Player
  }
}
    ${PlayerFragmentDoc}`;

export function useTopUpBalanceMutation() {
  return Urql.useMutation<TopUpBalanceMutation, TopUpBalanceMutationVariables>(TopUpBalanceDocument);
};
export const PrizeRedemptionDocument = gql`
    mutation prizeRedemption($id: String!, $amount: Int!) {
  prizeRedemption(id: $id, amount: $amount) {
    id
    firstName
    lastName
    email
    entry
    winnings
  }
}
    `;

export function usePrizeRedemptionMutation() {
  return Urql.useMutation<PrizeRedemptionMutation, PrizeRedemptionMutationVariables>(PrizeRedemptionDocument);
};
export const UpdatePlayerDocument = gql`
    mutation updatePlayer($input: UpdatePlayerInput!) {
  updatePlayer(input: $input) {
    ...Player
  }
}
    ${PlayerFragmentDoc}`;

export function useUpdatePlayerMutation() {
  return Urql.useMutation<UpdatePlayerMutation, UpdatePlayerMutationVariables>(UpdatePlayerDocument);
};
export const UpdatePlayerCardSerialNumberDocument = gql`
    mutation updatePlayerCardSerialNumber($playerId: String!, $cardSerialNumber: String) {
  updatePlayerCardSerialNumber(
    playerId: $playerId
    cardSerialNumber: $cardSerialNumber
  )
}
    `;

export function useUpdatePlayerCardSerialNumberMutation() {
  return Urql.useMutation<UpdatePlayerCardSerialNumberMutation, UpdatePlayerCardSerialNumberMutationVariables>(UpdatePlayerCardSerialNumberDocument);
};
export const UpdatePlayerPasswordDocument = gql`
    mutation updatePlayerPassword($id: String!) {
  updatePlayerPassword(id: $id)
}
    `;

export function useUpdatePlayerPasswordMutation() {
  return Urql.useMutation<UpdatePlayerPasswordMutation, UpdatePlayerPasswordMutationVariables>(UpdatePlayerPasswordDocument);
};
export const UpdatePlayerFullNameDocument = gql`
    mutation updatePlayerFullName($id: String!, $firstName: String!, $lastName: String!) {
  updatePlayerFullName(id: $id, firstName: $firstName, lastName: $lastName)
}
    `;

export function useUpdatePlayerFullNameMutation() {
  return Urql.useMutation<UpdatePlayerFullNameMutation, UpdatePlayerFullNameMutationVariables>(UpdatePlayerFullNameDocument);
};
export const UpdatePlayerEntryDocument = gql`
    mutation updatePlayerEntry($id: String!, $entry: Int!) {
  updatePlayerEntry(id: $id, entry: $entry)
}
    `;

export function useUpdatePlayerEntryMutation() {
  return Urql.useMutation<UpdatePlayerEntryMutation, UpdatePlayerEntryMutationVariables>(UpdatePlayerEntryDocument);
};
export const UpdatePlayerWinningsDocument = gql`
    mutation updatePlayerWinnings($id: String!, $winnings: Int!) {
  updatePlayerWinnings(id: $id, winnings: $winnings)
}
    `;

export function useUpdatePlayerWinningsMutation() {
  return Urql.useMutation<UpdatePlayerWinningsMutation, UpdatePlayerWinningsMutationVariables>(UpdatePlayerWinningsDocument);
};
export const UpdatePlayerPrizeRedemptionDocument = gql`
    mutation updatePlayerPrizeRedemption($id: String!, $prizeRedemption: Int!) {
  updatePlayerPrizeRedemption(id: $id, prizeRedemption: $prizeRedemption)
}
    `;

export function useUpdatePlayerPrizeRedemptionMutation() {
  return Urql.useMutation<UpdatePlayerPrizeRedemptionMutation, UpdatePlayerPrizeRedemptionMutationVariables>(UpdatePlayerPrizeRedemptionDocument);
};
export const UpdateRoomDocument = gql`
    mutation updateRoom($input: UpdateRoomInput!) {
  updateRoom(input: $input) {
    id
    name
    location
  }
}
    `;

export function useUpdateRoomMutation() {
  return Urql.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(UpdateRoomDocument);
};
export const CreateDiscordChannelDocument = gql`
    mutation createDiscordChannel {
  createDiscordChannel
}
    `;

export function useCreateDiscordChannelMutation() {
  return Urql.useMutation<CreateDiscordChannelMutation, CreateDiscordChannelMutationVariables>(CreateDiscordChannelDocument);
};
export const GetStationActiveVersionInstallerDocument = gql`
    query getStationActiveVersionInstaller {
  getStationActiveVersionInstaller
}
    `;

export function useGetStationActiveVersionInstallerQuery(options?: Omit<Urql.UseQueryArgs<GetStationActiveVersionInstallerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationActiveVersionInstallerQuery, GetStationActiveVersionInstallerQueryVariables>({ query: GetStationActiveVersionInstallerDocument, ...options });
};
export const CreateStationDocument = gql`
    mutation createStation($roomId: String!) {
  createStation(roomId: $roomId) {
    id
    roomId
    isActive
    secret
    createdAt
  }
}
    `;

export function useCreateStationMutation() {
  return Urql.useMutation<CreateStationMutation, CreateStationMutationVariables>(CreateStationDocument);
};
export const ResetStationSecretDocument = gql`
    mutation resetStationSecret($stationId: String!) {
  resetStationSecret(stationId: $stationId) {
    id
    roomId
    isActive
    secret
    createdAt
  }
}
    `;

export function useResetStationSecretMutation() {
  return Urql.useMutation<ResetStationSecretMutation, ResetStationSecretMutationVariables>(ResetStationSecretDocument);
};
export const ToggleStationDocument = gql`
    mutation toggleStation($id: String!) {
  toggleStation(id: $id) {
    id
    roomId
    isActive
    createdAt
  }
}
    `;

export function useToggleStationMutation() {
  return Urql.useMutation<ToggleStationMutation, ToggleStationMutationVariables>(ToggleStationDocument);
};


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Admin: ResolverTypeWrapper<Admin>;
  AdminLog: ResolverTypeWrapper<AdminLog>;
  AdminLogPagination: ResolverTypeWrapper<AdminLogPagination>;
  AdminPagination: ResolverTypeWrapper<AdminPagination>;
  AdminWithRooms: ResolverTypeWrapper<AdminWithRooms>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CreateAdminInput: CreateAdminInput;
  CreateGameInput: CreateGameInput;
  CreatePackageInput: CreatePackageInput;
  CreatePlayerInput: CreatePlayerInput;
  CreateRoomInput: CreateRoomInput;
  DataPoint: ResolverTypeWrapper<DataPoint>;
  DataPoints: ResolverTypeWrapper<DataPoints>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  EntryDataPoint: ResolverTypeWrapper<EntryDataPoint>;
  EntryDataPoints: ResolverTypeWrapper<EntryDataPoints>;
  File: ResolverTypeWrapper<Scalars['File']['output']>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Frequency: Frequency;
  Game: ResolverTypeWrapper<Game>;
  GameSource: GameSource;
  GameTag: ResolverTypeWrapper<GameTag>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntegerCursorPagination: IntegerCursorPagination;
  Mutation: ResolverTypeWrapper<{}>;
  NonNegativeInt: ResolverTypeWrapper<Scalars['NonNegativeInt']['output']>;
  Package: ResolverTypeWrapper<Package>;
  PackageWithAmount: ResolverTypeWrapper<PackageWithAmount>;
  Pet: ResolverTypeWrapper<Pet>;
  PetPackage: ResolverTypeWrapper<PetPackage>;
  PlayTransaction: ResolverTypeWrapper<PlayTransaction>;
  PlayTransactionPagination: ResolverTypeWrapper<PlayTransactionPagination>;
  PlayTransactionType: PlayTransactionType;
  Player: ResolverTypeWrapper<Player>;
  PlayerSession: ResolverTypeWrapper<PlayerSession>;
  PlayersQuery: ResolverTypeWrapper<PlayersQuery>;
  PositiveInt: ResolverTypeWrapper<Scalars['PositiveInt']['output']>;
  Query: ResolverTypeWrapper<{}>;
  Room: ResolverTypeWrapper<Room>;
  SearchPlayersInput: SearchPlayersInput;
  Station: ResolverTypeWrapper<Station>;
  StationWithSecret: ResolverTypeWrapper<StationWithSecret>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringCursorPagination: StringCursorPagination;
  TauriAppVersion: ResolverTypeWrapper<TauriAppVersion>;
  Transaction: ResolverTypeWrapper<Transaction>;
  TransactionPagination: ResolverTypeWrapper<TransactionPagination>;
  TransactionType: TransactionType;
  UpdateAdminInput: UpdateAdminInput;
  UpdateGameInput: UpdateGameInput;
  UpdatePackageInput: UpdatePackageInput;
  UpdatePlayerInput: UpdatePlayerInput;
  UpdateRoomInput: UpdateRoomInput;
  UpdateStationInput: UpdateStationInput;
  getPlayTransactionListFilter: GetPlayTransactionListFilter;
  getTransactionListFilter: GetTransactionListFilter;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Admin: Admin;
  AdminLog: AdminLog;
  AdminLogPagination: AdminLogPagination;
  AdminPagination: AdminPagination;
  AdminWithRooms: AdminWithRooms;
  Boolean: Scalars['Boolean']['output'];
  CreateAdminInput: CreateAdminInput;
  CreateGameInput: CreateGameInput;
  CreatePackageInput: CreatePackageInput;
  CreatePlayerInput: CreatePlayerInput;
  CreateRoomInput: CreateRoomInput;
  DataPoint: DataPoint;
  DataPoints: DataPoints;
  DateTime: Scalars['DateTime']['output'];
  EntryDataPoint: EntryDataPoint;
  EntryDataPoints: EntryDataPoints;
  File: Scalars['File']['output'];
  Float: Scalars['Float']['output'];
  Game: Game;
  GameTag: GameTag;
  Int: Scalars['Int']['output'];
  IntegerCursorPagination: IntegerCursorPagination;
  Mutation: {};
  NonNegativeInt: Scalars['NonNegativeInt']['output'];
  Package: Package;
  PackageWithAmount: PackageWithAmount;
  Pet: Pet;
  PetPackage: PetPackage;
  PlayTransaction: PlayTransaction;
  PlayTransactionPagination: PlayTransactionPagination;
  Player: Player;
  PlayerSession: PlayerSession;
  PlayersQuery: PlayersQuery;
  PositiveInt: Scalars['PositiveInt']['output'];
  Query: {};
  Room: Room;
  SearchPlayersInput: SearchPlayersInput;
  Station: Station;
  StationWithSecret: StationWithSecret;
  String: Scalars['String']['output'];
  StringCursorPagination: StringCursorPagination;
  TauriAppVersion: TauriAppVersion;
  Transaction: Transaction;
  TransactionPagination: TransactionPagination;
  UpdateAdminInput: UpdateAdminInput;
  UpdateGameInput: UpdateGameInput;
  UpdatePackageInput: UpdatePackageInput;
  UpdatePlayerInput: UpdatePlayerInput;
  UpdateRoomInput: UpdateRoomInput;
  UpdateStationInput: UpdateStationInput;
  getPlayTransactionListFilter: GetPlayTransactionListFilter;
  getTransactionListFilter: GetTransactionListFilter;
};

export type AdminResolvers<ContextType = any, ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']> = {
  adminRoleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  has2fa?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminLog'] = ResolversParentTypes['AdminLog']> = {
  adminId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminLogPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminLogPagination'] = ResolversParentTypes['AdminLogPagination']> = {
  adminLogs?: Resolver<Array<ResolversTypes['AdminLog']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminPagination'] = ResolversParentTypes['AdminPagination']> = {
  admins?: Resolver<Array<ResolversTypes['Admin']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminWithRoomsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminWithRooms'] = ResolversParentTypes['AdminWithRooms']> = {
  adminRoleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roomIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataPoint'] = ResolversParentTypes['DataPoint']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataPointsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataPoints'] = ResolversParentTypes['DataPoints']> = {
  dataPoints?: Resolver<Array<ResolversTypes['DataPoint']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type EntryDataPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryDataPoint'] = ResolversParentTypes['EntryDataPoint']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntryDataPointsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryDataPoints'] = ResolversParentTypes['EntryDataPoints']> = {
  dataPoints?: Resolver<Array<ResolversTypes['EntryDataPoint']>, ParentType, ContextType>;
  numberOfEntries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FileScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['File'], any> {
  name: 'File';
}

export type GameResolvers<ContextType = any, ParentType extends ResolversParentTypes['Game'] = ResolversParentTypes['Game']> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTags?: Resolver<Array<ResolversTypes['GameTag']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imgUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameTag'] = ResolversParentTypes['GameTag']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addGameTagToGame?: Resolver<ResolversTypes['Game'], ParentType, ContextType, RequireFields<MutationAddGameTagToGameArgs, 'gameId' | 'gameTagId'>>;
  attachRoom?: Resolver<ResolversTypes['AdminWithRooms'], ParentType, ContextType, RequireFields<MutationAttachRoomArgs, 'id' | 'roomId'>>;
  buyPetPackage?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationBuyPetPackageArgs, 'petPackageId' | 'playerId'>>;
  createAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationCreateAdminArgs, 'input'>>;
  createDiscordChannel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createGame?: Resolver<ResolversTypes['Game'], ParentType, ContextType, RequireFields<MutationCreateGameArgs, 'input'>>;
  createGameTag?: Resolver<ResolversTypes['GameTag'], ParentType, ContextType, RequireFields<MutationCreateGameTagArgs, 'name'>>;
  createPackage?: Resolver<ResolversTypes['Package'], ParentType, ContextType, RequireFields<MutationCreatePackageArgs, 'input'>>;
  createPlayer?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationCreatePlayerArgs, 'input'>>;
  createRoom?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<MutationCreateRoomArgs, 'input'>>;
  createStation?: Resolver<ResolversTypes['StationWithSecret'], ParentType, ContextType, RequireFields<MutationCreateStationArgs, 'roomId'>>;
  deleteGameTag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteGameTagArgs, 'id'>>;
  deletePackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePackageArgs, 'id' | 'twoFAToken'>>;
  detachRoom?: Resolver<ResolversTypes['AdminWithRooms'], ParentType, ContextType, RequireFields<MutationDetachRoomArgs, 'id' | 'roomId'>>;
  prizeRedemption?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationPrizeRedemptionArgs, 'amount' | 'id'>>;
  removeGameTagFromGame?: Resolver<ResolversTypes['Game'], ParentType, ContextType, RequireFields<MutationRemoveGameTagFromGameArgs, 'gameId' | 'gameTagId'>>;
  requestTwoFactorAuth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resetStationSecret?: Resolver<ResolversTypes['StationWithSecret'], ParentType, ContextType, RequireFields<MutationResetStationSecretArgs, 'stationId'>>;
  setPlayerEmailAndSalt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetPlayerEmailAndSaltArgs, 'email' | 'id'>>;
  setupTwoFactorAuth?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationSetupTwoFactorAuthArgs, 'token'>>;
  toggleAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationToggleAdminArgs, 'id'>>;
  toggleStation?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<MutationToggleStationArgs, 'id'>>;
  toggleTauriAppActiveVersion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationToggleTauriAppActiveVersionArgs, 'appType' | 'versionId'>>;
  topUpBalance?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationTopUpBalanceArgs, 'packages' | 'playerId'>>;
  updateAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationUpdateAdminArgs, 'input'>>;
  updateGame?: Resolver<ResolversTypes['Game'], ParentType, ContextType, RequireFields<MutationUpdateGameArgs, 'input'>>;
  updateGameTag?: Resolver<ResolversTypes['GameTag'], ParentType, ContextType, RequireFields<MutationUpdateGameTagArgs, 'id' | 'name'>>;
  updateOperatorReleaseNote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateOperatorReleaseNoteArgs, 'releaseNote' | 'version'>>;
  updatePackage?: Resolver<ResolversTypes['Package'], ParentType, ContextType, RequireFields<MutationUpdatePackageArgs, 'input'>>;
  updatePassword?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'id' | 'password'>>;
  updatePlayer?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationUpdatePlayerArgs, 'input'>>;
  updatePlayerCardSerialNumber?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerCardSerialNumberArgs, 'playerId'>>;
  updatePlayerEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerEmailArgs, 'email' | 'id'>>;
  updatePlayerEntry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerEntryArgs, 'entry' | 'id'>>;
  updatePlayerFirstName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerFirstNameArgs, 'firstName' | 'id'>>;
  updatePlayerFullName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerFullNameArgs, 'firstName' | 'id' | 'lastName'>>;
  updatePlayerLastName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerLastNameArgs, 'id' | 'lastName'>>;
  updatePlayerPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerPasswordArgs, 'id'>>;
  updatePlayerPhoneNumber?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerPhoneNumberArgs, 'id' | 'phoneNumber'>>;
  updatePlayerPrizeRedemption?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerPrizeRedemptionArgs, 'id' | 'prizeRedemption'>>;
  updatePlayerWinnings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerWinningsArgs, 'id' | 'winnings'>>;
  updateRoom?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<MutationUpdateRoomArgs, 'input'>>;
  updateRoomBps?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateRoomBpsArgs, 'bps' | 'roomId' | 'twoFAToken'>>;
  updateStation?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<MutationUpdateStationArgs, 'input'>>;
  updateStationReleaseNote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateStationReleaseNoteArgs, 'releaseNote' | 'version'>>;
};

export interface NonNegativeIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeInt'], any> {
  name: 'NonNegativeInt';
}

export type PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Package'] = ResolversParentTypes['Package']> = {
  candy?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageWithAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageWithAmount'] = ResolversParentTypes['PackageWithAmount']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  candy?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pet'] = ResolversParentTypes['Pet']> = {
  candyEarned?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  candyToNextLevel?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PetPackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['PetPackage'] = ResolversParentTypes['PetPackage']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayTransaction'] = ResolversParentTypes['PlayTransaction']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameSessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayTransactionPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayTransactionPagination'] = ResolversParentTypes['PlayTransactionPagination']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['PlayTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Player'] = ResolversParentTypes['Player']> = {
  bonus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  candy?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cardSerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entry?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  isPlayFromHome?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSessionActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pets?: Resolver<Array<ResolversTypes['Pet']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prizeRedemption?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  room?: Resolver<Maybe<ResolversTypes['Room']>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winnings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayerSession'] = ResolversParentTypes['PlayerSession']> = {
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endEntry?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endWinnings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prizeRedemption?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startEntry?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startWinnings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayersQuery'] = ResolversParentTypes['PlayersQuery']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  players?: Resolver<Array<ResolversTypes['Player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PositiveIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PositiveInt'], any> {
  name: 'PositiveInt';
}

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  countStationsByRoomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCountStationsByRoomIdArgs, 'roomId'>>;
  countTransactionsByRoomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCountTransactionsByRoomIdArgs, 'roomId'>>;
  getActivePackages?: Resolver<Array<ResolversTypes['Package']>, ParentType, ContextType>;
  getAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType>;
  getAdminById?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<QueryGetAdminByIdArgs, 'adminId'>>;
  getAdminList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetAdminListArgs>>;
  getAdminListByRoomId?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, RequireFields<QueryGetAdminListByRoomIdArgs, 'roomId'>>;
  getAdminLogListByAdminId?: Resolver<ResolversTypes['AdminLogPagination'], ParentType, ContextType, RequireFields<QueryGetAdminLogListByAdminIdArgs, 'adminId'>>;
  getArchivedPackages?: Resolver<Array<ResolversTypes['Package']>, ParentType, ContextType>;
  getAverageEntryByGameId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetAverageEntryByGameIdArgs, 'frequency' | 'gameId'>>;
  getAverageEntryByPlayerId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetAverageEntryByPlayerIdArgs, 'frequency' | 'playerId'>>;
  getAverageSessionDurationByPlayerId?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryGetAverageSessionDurationByPlayerIdArgs, 'playerId'>>;
  getEntriesByGameId?: Resolver<ResolversTypes['EntryDataPoints'], ParentType, ContextType, RequireFields<QueryGetEntriesByGameIdArgs, 'frequency' | 'gameId'>>;
  getEntriesByRoomId?: Resolver<ResolversTypes['EntryDataPoints'], ParentType, ContextType, RequireFields<QueryGetEntriesByRoomIdArgs, 'frequency' | 'roomId'>>;
  getEntriesByStationId?: Resolver<ResolversTypes['EntryDataPoints'], ParentType, ContextType, RequireFields<QueryGetEntriesByStationIdArgs, 'frequency' | 'stationId'>>;
  getGameList?: Resolver<Array<ResolversTypes['Game']>, ParentType, ContextType, Partial<QueryGetGameListArgs>>;
  getGameTags?: Resolver<Array<ResolversTypes['GameTag']>, ParentType, ContextType>;
  getNetRevenueByAdminId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetNetRevenueByAdminIdArgs, 'adminId' | 'frequency' | 'startDate'>>;
  getNetRevenueByPlayerId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetNetRevenueByPlayerIdArgs, 'frequency' | 'playerId' | 'startDate'>>;
  getNetRevenueByRoomId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetNetRevenueByRoomIdArgs, 'frequency' | 'roomId' | 'startDate'>>;
  getOperatorActiveVersionInstaller?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getOperatorAppActiveVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getOperatorById?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<QueryGetOperatorByIdArgs, 'adminId'>>;
  getOperatorList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetOperatorListArgs>>;
  getPersonaList?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  getPersonaListBelow?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  getPetPackageList?: Resolver<Array<ResolversTypes['PetPackage']>, ParentType, ContextType>;
  getPlayTransactionListByPlayerId?: Resolver<ResolversTypes['PlayTransactionPagination'], ParentType, ContextType, RequireFields<QueryGetPlayTransactionListByPlayerIdArgs, 'playerId'>>;
  getPlayTransactionListByRoomId?: Resolver<ResolversTypes['PlayTransactionPagination'], ParentType, ContextType, RequireFields<QueryGetPlayTransactionListByRoomIdArgs, 'roomId'>>;
  getPlayerById?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<QueryGetPlayerByIdArgs, 'playerId'>>;
  getPlayerByIdOrCardSerialNumber?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<QueryGetPlayerByIdOrCardSerialNumberArgs, 'playerIdOrCardSerialNumber'>>;
  getPlayerList?: Resolver<ResolversTypes['PlayersQuery'], ParentType, ContextType, Partial<QueryGetPlayerListArgs>>;
  getPlayerListByRoomId?: Resolver<ResolversTypes['PlayersQuery'], ParentType, ContextType, RequireFields<QueryGetPlayerListByRoomIdArgs, 'roomId'>>;
  getRoomById?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<QueryGetRoomByIdArgs, 'roomId'>>;
  getRoomList?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType>;
  getRoomListByAdminId?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType, RequireFields<QueryGetRoomListByAdminIdArgs, 'adminId'>>;
  getRoomNetRevenueBps?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryGetRoomNetRevenueBpsArgs, 'roomId'>>;
  getRoomPayToProvider?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, RequireFields<QueryGetRoomPayToProviderArgs, 'roomId' | 'startDate'>>;
  getSalesRepList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetSalesRepListArgs>>;
  getStationActiveVersionInstaller?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getStationAppActiveVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getStationById?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<QueryGetStationByIdArgs, 'stationId'>>;
  getStationList?: Resolver<Array<Maybe<ResolversTypes['Station']>>, ParentType, ContextType>;
  getStationListByRoomId?: Resolver<Array<Maybe<ResolversTypes['Station']>>, ParentType, ContextType, RequireFields<QueryGetStationListByRoomIdArgs, 'roomId'>>;
  getStationReleaseNote?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetStationReleaseNoteArgs, 'version'>>;
  getStationsForOperator?: Resolver<Array<ResolversTypes['Station']>, ParentType, ContextType, RequireFields<QueryGetStationsForOperatorArgs, 'adminId'>>;
  getSuperAdminList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetSuperAdminListArgs>>;
  getTauriAppVersions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TauriAppVersion']>>>, ParentType, ContextType, RequireFields<QueryGetTauriAppVersionsArgs, 'appType'>>;
  getTransactionList?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType, Partial<QueryGetTransactionListArgs>>;
  getTransactionListByPlayerId?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType, RequireFields<QueryGetTransactionListByPlayerIdArgs, 'playerId'>>;
  getTransactionListByRoomId?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType, RequireFields<QueryGetTransactionListByRoomIdArgs, 'roomId'>>;
  issueCardValidation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIssueCardValidationArgs, 'playerId'>>;
  searchPlayers?: Resolver<ResolversTypes['PlayersQuery'], ParentType, ContextType, RequireFields<QuerySearchPlayersArgs, 'input'>>;
};

export type RoomResolvers<ContextType = any, ParentType extends ResolversParentTypes['Room'] = ResolversParentTypes['Room']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isReadyForUpdate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Station'] = ResolversParentTypes['Station']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  playerSession?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlayerSession']>>>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stationAppVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationWithSecretResolvers<ContextType = any, ParentType extends ResolversParentTypes['StationWithSecret'] = ResolversParentTypes['StationWithSecret']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TauriAppVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TauriAppVersion'] = ResolversParentTypes['TauriAppVersion']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pubDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  adminId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adminName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  packages?: Resolver<Maybe<Array<Maybe<ResolversTypes['PackageWithAmount']>>>, ParentType, ContextType>;
  petPackage?: Resolver<Maybe<ResolversTypes['PetPackage']>, ParentType, ContextType>;
  playerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionPagination'] = ResolversParentTypes['TransactionPagination']> = {
  cursor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDeposits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDepositsAndWithdraws?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalWithdraws?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Admin?: AdminResolvers<ContextType>;
  AdminLog?: AdminLogResolvers<ContextType>;
  AdminLogPagination?: AdminLogPaginationResolvers<ContextType>;
  AdminPagination?: AdminPaginationResolvers<ContextType>;
  AdminWithRooms?: AdminWithRoomsResolvers<ContextType>;
  DataPoint?: DataPointResolvers<ContextType>;
  DataPoints?: DataPointsResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  EntryDataPoint?: EntryDataPointResolvers<ContextType>;
  EntryDataPoints?: EntryDataPointsResolvers<ContextType>;
  File?: GraphQLScalarType;
  Game?: GameResolvers<ContextType>;
  GameTag?: GameTagResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NonNegativeInt?: GraphQLScalarType;
  Package?: PackageResolvers<ContextType>;
  PackageWithAmount?: PackageWithAmountResolvers<ContextType>;
  Pet?: PetResolvers<ContextType>;
  PetPackage?: PetPackageResolvers<ContextType>;
  PlayTransaction?: PlayTransactionResolvers<ContextType>;
  PlayTransactionPagination?: PlayTransactionPaginationResolvers<ContextType>;
  Player?: PlayerResolvers<ContextType>;
  PlayerSession?: PlayerSessionResolvers<ContextType>;
  PlayersQuery?: PlayersQueryResolvers<ContextType>;
  PositiveInt?: GraphQLScalarType;
  Query?: QueryResolvers<ContextType>;
  Room?: RoomResolvers<ContextType>;
  Station?: StationResolvers<ContextType>;
  StationWithSecret?: StationWithSecretResolvers<ContextType>;
  TauriAppVersion?: TauriAppVersionResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  TransactionPagination?: TransactionPaginationResolvers<ContextType>;
};

